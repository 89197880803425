import React from "react"
import { graphql } from "gatsby"

import AlternatingList from "../components/alternatingList"
import EventTitle from "../components/eventTitle"
import SpeakerName from "../components/speakerName"
import SpeakerCard from "../components/speakerCard"

import style from "../components/alternatingList.module.css"

const Speakers = ({ data }) => (
	<AlternatingList
		type="Speakers"
		meta={{
			title: "Speakers",
			description: "Check out Accento's speaker lineup!",
			keywords: `Java speaker online`,
			path: "/speakers",
		}}
		snippetId="speakers-intro"
	>
		{generateSpeakers(data.speakers.edges)}
	</AlternatingList>
)

function generateSpeakers(speakers) {
	if (!speakers || speakers.length === 0) return <span />
	return speakers
		.filter(({ node }) => !node.frontmatter.canceled)
		.map(({ node }) => generateSpeaker(node))
}

function generateSpeaker(speaker) {
	return (
		<div key={speaker.frontmatter.slug} className={style.container}>
			<SpeakerCard
				speakerSlug={speaker.frontmatter.slug}
				size="small"
				linkImage
				className={style.image}
			/>
			<div className={style.event}>
				<h2 className={style.title}>
					<SpeakerName speaker={speaker} linkName />
				</h2>
				<p className={style.teaser}>{speaker.frontmatter.bio}</p>
				{eventList("Talk", "Talks", speaker.frontmatter.talks)}
				{eventList("Lightning Talk", "Lightning Talks", speaker.frontmatter.lightningTalks)}
				{eventList("Q&A", "Q&As", speaker.frontmatter.qa)}
				{eventList("Panel", "Panels", speaker.frontmatter.panels)}
				{eventList("Training", "Trainings", speaker.frontmatter.trainings)}
			</div>
		</div>
	)
}

function eventList(typeSingular, typePlural, events) {
	if (!events || events.length === 0) return

	const type = events.length === 1 ? typeSingular : typePlural
	return (
		<p className={style.teaser}>
			<span style={{ fontWeight: "bold" }}>{type}: </span>
			{list(events)}
		</p>
	)
}

function list(events) {
	return events.map((event, index) => (
		<span key={event.fields.path}>
			<EventTitle event={event} linkTitle />
			{index < events.length - 1 && <span>, </span>}
		</span>
	))
}

export const query = graphql`
	query {
		speakers: allMarkdownRemark(
			sort: { fields: frontmatter___priority, order: ASC }
			filter: { fields: { collection: { eq: "speakers" } } }
		) {
			edges {
				node {
					fields {
						path
					}
					frontmatter {
						name
						slug
						canceled
						bio
						talks {
							...SpeakersEventPreview
						}
						panels {
							...SpeakersEventPreview
						}
					}
				}
			}
		}
	}
	fragment SpeakersEventPreview on MarkdownRemark {
		fields {
			path
		}
		frontmatter {
			title
			language
		}
	}
`

export default Speakers
