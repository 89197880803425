import React from "react"

import Layout from "./layout"
import Snippet from "./snippet"

const AlternatingList = ({ meta, snippetId, children }) => {
	return (
		<Layout meta={meta}>
			<div>
				<Snippet id={snippetId} />
			</div>
			{children}
		</Layout>
	)
}

export default AlternatingList
